/*------ 23. Blog Details Page  -------*/

.blog-details-top {
  .blog-details-img {
    position: relative;
    img {
      width: 100%;
    }
    .video-icon {
      left: 0;
      position: absolute;
      right: 0;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      a {
        background-color: #ff0000;
        border-radius: 50px;
        color: #fff;
        display: inline-block;
        font-size: 25px;
        height: 60px;
        line-height: 61px;
        width: 60px;
        &:hover {
          background-color: $theme-color;
        }
      }
    }
  }
  .blog-details-content {
    margin-top: 30px;
    img, iframe, video {
      max-width: 100%;
      object-fit: contain;
    }
    h3 {
      font-size: 24px;
      font-weight: 500;
      margin: 8px 0 20px;
      @media #{$lg-layout} {
        font-size: 22px;
      }
      @media #{$md-layout} {
        font-size: 22px;
      }
      @media #{$xs-layout} {
        font-size: 18px;
      }
    }
    p {
      margin: 0;
      line-height: 26px;
    }
    blockquote {
      color: #333;
      font-size: 18px;
      margin: 25px 0 26px;
      line-height: 34px;
      font-weight: 500;
      font-style: italic;
      @media #{$lg-layout} {
        font-size: 17px;
      }
      @media #{$md-layout} {
        font-size: 17px;
      }
      @media #{$xs-layout} {
        font-size: 16px;
        line-height: 30px;
      }
    }
  }
}

.dec-img-wrapper {
  margin-top: 50px;
  @media #{$xs-layout} {
    margin-top: 30px;
  }
  .dec-img {
    img {
      width: 100%;
    }
    &.mb-50 {
      @media #{$xs-layout} {
        margin-bottom: 30px;
      }
    }
  }
}

.tag-share {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 30px;
  @media #{$xs-layout} {
    display: block;
    margin: 20px 0 25px;
  }
  .dec-tag {
    @media #{$xs-layout} {
      margin-bottom: 10px;
    }
    ul {
      display: flex;
      li {
        margin-right: 10px;
        position: relative;
        a {
          text-transform: capitalize;
          font-size: 15px;
        }
      }
    }
  }
  .blog-share {
    display: flex;
    align-items: center;
    span {
      font-size: 15px;
      text-transform: capitalize;
    }
    ul {
      display: flex;
      li {
        margin-left: 10px;
        a {
          font-size: 16px;
        }
      }
    }
  }
}

.next-previous-post {
  border-bottom: 1px solid #f1f1f1;
  border-top: 1px solid #f1f1f1;
  padding: 18px 0 17px;
  display: flex;
  justify-content: space-between;
  a {
    font-size: 15px;
    text-transform: capitalize;
  }
}

.blog-comment-wrapper {
  @media #{$xs-layout} {
    &.mt-55 {
      margin-top: 25px;
    }
  }
  .blog-dec-title {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
    @media #{$xs-layout} {
      font-size: 18px;
    }
  }
  .single-comment-wrapper {
    display: flex;
    @media #{$xs-layout} {
      display: block;
      &.mt-35 {
        margin-top: 20px;
      }
      &.mt-50 {
        margin-top: 30px;
      }
    }
    .blog-comment-img {
      flex: 0 0 120px;
      margin-right: 28px;
      @media #{$xs-layout} {
        margin-right: 28px;
        margin-bottom: 20px;
      }
      img {
        width: 100%;
        @media #{$xs-layout} {
          width: auto;
        }
      }
    }
    .blog-comment-content {
      h4 {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        line-height: 1;
      }
      span {
        display: block;
        font-size: 15px;
        margin: 6px 0 8px;
      }
      p {
        font-size: 14px;
        margin-bottom: 10px;
      }
      .blog-details-btn {
        a {
          font-size: 14px;
          font-weight: 500;
          text-transform: capitalize;
        }
      }
    }
    &.ml-120 {
      @media #{$xs-layout} {
        margin-left: 0;
      }
    }
  }
}

.blog-reply-wrapper {
  @media #{$xs-layout} {
    &.mt-50 {
      margin-top: 30px;
    }
  }
  .blog-dec-title {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
    @media #{$xs-layout} {
      font-size: 18px;
    }
  }
  .blog-form {
    margin-top: 35px;
    @media #{$xs-layout} {
      margin-top: 20px;
    }
    .leave-form {
      input {
        background: transparent none repeat scroll 0 0;
        border: 1px solid #eaeaea;
        box-shadow: none;
        color: #333;
        height: 45px;
        margin-bottom: 30px;
        padding-left: 20px;
      }
    }
    .text-leave {
      textarea {
        background: transparent none repeat scroll 0 0;
        border: 1px solid #eaeaea;
        box-shadow: none;
        color: #333;
        height: 250px;
        padding-top: 20px;
        margin-bottom: 20px;
        padding-left: 20px;
      }
      input {
        background-color: $theme-color;
        border: medium none;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-weight: 500;
        height: inherit;
        letter-spacing: 1px;
        margin-top: 10px;
        padding: 14px 30px 13px;
        width: inherit;
        &:hover {
          background-color: #333;
        }
      }
    }
  }
}

.nav-style-4.owl-carousel:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
}
