.customer-redemption-table-content {
  table {
    border: none;
    width: 100%;
    thead > tr {
      border-bottom: 1px solid #ebebeb;
      th {
        border: none;
        color: #666666;
        font-size: 14px;
        font-weight: 500;
        padding: 21px 45px 22px;
        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
        @media #{$lg-layout} {
          padding: 21px 35px 22px;
        }
        @media #{$xs-layout} {
          padding: 21px 20px 22px;
        }
      }
    }
    tbody > tr {
      border-bottom: 1px solid #ebebeb;
      td.product-thumbnail {
        width: 150px;
        min-width: 150px;
        height: 150px;
        min-height: 150px;
        object-fit: cover;
      }
      td p {
        margin-bottom: 0;
      }
      td.staff-code {

        input {
          width: 200px;
          margin-right: 20px;
        }

        .submit-btn {
          background-color: $theme-color;
          border: medium none;
          border-radius: 5px;
          color: #fff;
          cursor: pointer;
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
          padding: 11px 30px;
          text-transform: uppercase;
          transition: all 0.3s ease 0s;
          margin: 20px 0px;
          &:hover {
            background-color: #f2f2f2;
            color: #333;
          }
        }
      }
      td {
        color: #333;
        font-size: 15px;
        padding: 30px;
        text-align: center;
      }
      .no-result {
        text-align: center;
        width: 100%;
        color: grey;
      }
    }
  }
}
