.qr-code-scanner-wrapper {
  .modal-body {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .qr-code-reader-wrapper {
    width: 60%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    border: 1px grey solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h4 {
      position: absolute; width: 50%; text-align: center;
    }

    .qr-code-reader {
      width: 100%;
    }
  }

  .submit-btn {
    background-color: $theme-color;
    border: medium none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding: 11px 30px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    margin: 20px 0px;
    &:hover {
      background-color: #f2f2f2;
      color: #333;
    }
  }

  h4 {
    color: #666666;
    font-weight: 500;
  }

  form {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  input {
    width: 60%;
  }

  .blurred-background {
    position: absolute;
    background-color: white;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    top: 0;
  }
}
