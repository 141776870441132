@font-face {
	font-family: 'Pe-icon-7-filled';
	src:url('../fonts/Pe-icon-7-filled.eot?kinggk');
	src:url('../fonts/Pe-icon-7-filled.eot?#iefixkinggk') format('embedded-opentype'),
		url('../fonts/Pe-icon-7-filled.woff?kinggk') format('woff'),
		url('../fonts/Pe-icon-7-filled.ttf?kinggk') format('truetype'),
		url('../fonts/Pe-icon-7-filled.svg?kinggk#Pe-icon-7-filled') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="pe-7f-"], [class*=" pe-7f-"] {
	display: inline-block;
	font-family: 'Pe-icon-7-filled';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.pe-7f-album:before {
	content: "\e625";
}
.pe-7f-arc:before {
	content: "\e626";
}
.pe-7f-back-2:before {
	content: "\e627";
}
.pe-7f-bandaid:before {
	content: "\e628";
}
.pe-7f-car:before {
	content: "\e629";
}
.pe-7f-diamond:before {
	content: "\e62a";
}
.pe-7f-door-lock:before {
	content: "\e62b";
}
.pe-7f-eyedropper:before {
	content: "\e62c";
}
.pe-7f-female:before {
	content: "\e62d";
}
.pe-7f-gym:before {
	content: "\e62e";
}
.pe-7f-hammer:before {
	content: "\e62f";
}
.pe-7f-headphones:before {
	content: "\e630";
}
.pe-7f-helm:before {
	content: "\e631";
}
.pe-7f-hourglass:before {
	content: "\e632";
}
.pe-7f-leaf:before {
	content: "\e633";
}
.pe-7f-magic-wand:before {
	content: "\e634";
}
.pe-7f-male:before {
	content: "\e635";
}
.pe-7f-map-2:before {
	content: "\e636";
}
.pe-7f-next-2:before {
	content: "\e637";
}
.pe-7f-paint-bucket:before {
	content: "\e638";
}
.pe-7f-pendrive:before {
	content: "\e639";
}
.pe-7f-photo:before {
	content: "\e63a";
}
.pe-7f-piggy:before {
	content: "\e63b";
}
.pe-7f-plugin:before {
	content: "\e63c";
}
.pe-7f-refresh-2:before {
	content: "\e63d";
}
.pe-7f-rocket:before {
	content: "\e63e";
}
.pe-7f-settings:before {
	content: "\e63f";
}
.pe-7f-shield:before {
	content: "\e640";
}
.pe-7f-smile:before {
	content: "\e641";
}
.pe-7f-usb:before {
	content: "\e642";
}
.pe-7f-vector:before {
	content: "\e643";
}
.pe-7f-wine:before {
	content: "\e644";
}
.pe-7f-cloud-upload:before {
	content: "\e605";
}
.pe-7f-angle-up:before {
	content: "\e601";
}
.pe-7f-cloud-download:before {
	content: "\e606";
}
.pe-7f-angle-right:before {
	content: "\e602";
}
.pe-7f-angle-left:before {
	content: "\e603";
}
.pe-7f-angle-down:before {
	content: "\e604";
}
.pe-7f-wallet:before {
	content: "\e680";
}
.pe-7f-volume2:before {
	content: "\e681";
}
.pe-7f-volume1:before {
	content: "\e682";
}
.pe-7f-voicemail:before {
	content: "\e683";
}
.pe-7f-video:before {
	content: "\e684";
}
.pe-7f-user:before {
	content: "\e685";
}
.pe-7f-upload:before {
	content: "\e686";
}
.pe-7f-unlock:before {
	content: "\e687";
}
.pe-7f-umbrella:before {
	content: "\e688";
}
.pe-7f-trash:before {
	content: "\e689";
}
.pe-7f-tools:before {
	content: "\e68a";
}
.pe-7f-timer:before {
	content: "\e68b";
}
.pe-7f-ticket:before {
	content: "\e68c";
}
.pe-7f-target:before {
	content: "\e68d";
}
.pe-7f-sun:before {
	content: "\e68e";
}
.pe-7f-study:before {
	content: "\e68f";
}
.pe-7f-stopwatch:before {
	content: "\e690";
}
.pe-7f-star:before {
	content: "\e691";
}
.pe-7f-speaker:before {
	content: "\e692";
}
.pe-7f-signal:before {
	content: "\e693";
}
.pe-7f-shuffle:before {
	content: "\e694";
}
.pe-7f-shopbag:before {
	content: "\e695";
}
.pe-7f-share:before {
	content: "\e696";
}
.pe-7f-server:before {
	content: "\e697";
}
.pe-7f-search:before {
	content: "\e698";
}
.pe-7f-science:before {
	content: "\e699";
}
.pe-7f-ribbon:before {
	content: "\e69a";
}
.pe-7f-repeat:before {
	content: "\e69b";
}
.pe-7f-refresh:before {
	content: "\e69c";
}
.pe-7f-refresh-cloud:before {
	content: "\e69d";
}
.pe-7f-radio:before {
	content: "\e69e";
}
.pe-7f-print:before {
	content: "\e69f";
}
.pe-7f-prev:before {
	content: "\e6a0";
}
.pe-7f-power:before {
	content: "\e6a1";
}
.pe-7f-portfolio:before {
	content: "\e6a2";
}
.pe-7f-plus:before {
	content: "\e6a3";
}
.pe-7f-play:before {
	content: "\e6a4";
}
.pe-7f-plane:before {
	content: "\e6a5";
}
.pe-7f-photo-gallery:before {
	content: "\e6a6";
}
.pe-7f-phone:before {
	content: "\e6a7";
}
.pe-7f-pen:before {
	content: "\e6a8";
}
.pe-7f-paper-plane:before {
	content: "\e6a9";
}
.pe-7f-bluetooth:before {
	content: "\e607";
}
.pe-7f-paint:before {
	content: "\e6aa";
}
.pe-7f-wristwatch:before {
	content: "\e608";
}
.pe-7f-notebook:before {
	content: "\e6ab";
}
.pe-7f-world:before {
	content: "\e609";
}
.pe-7f-note:before {
	content: "\e6ac";
}
.pe-7f-volume:before {
	content: "\e60a";
}
.pe-7f-paperclip:before {
	content: "\e614";
}
.pe-7f-news-paper:before {
	content: "\e6ae";
}
.pe-7f-note2:before {
	content: "\e615";
}
.pe-7f-musiclist:before {
	content: "\e6af";
}
.pe-7f-network:before {
	content: "\e616";
}
.pe-7f-music:before {
	content: "\e6b0";
}
.pe-7f-mouse:before {
	content: "\e6b1";
}
.pe-7f-cash:before {
	content: "\e621";
}
.pe-7f-more:before {
	content: "\e6b2";
}
.pe-7f-add-user:before {
	content: "\e622";
}
.pe-7f-moon:before {
	content: "\e6b3";
}
.pe-7f-way:before {
	content: "\e623";
}
.pe-7f-monitor:before {
	content: "\e6b4";
}
.pe-7f-bottom-arrow:before {
	content: "\e624";
}
.pe-7f-micro:before {
	content: "\e6b5";
}
.pe-7f-medal:before {
	content: "\e618";
}
.pe-7f-menu:before {
	content: "\e6b6";
}
.pe-7f-like2:before {
	content: "\e619";
}
.pe-7f-map:before {
	content: "\e6b7";
}
.pe-7f-left-arrow:before {
	content: "\e61a";
}
.pe-7f-map-marker:before {
	content: "\e6b8";
}
.pe-7f-key:before {
	content: "\e61b";
}
.pe-7f-mail:before {
	content: "\e6b9";
}
.pe-7f-joy:before {
	content: "\e61c";
}
.pe-7f-mail-open:before {
	content: "\e6ba";
}
.pe-7f-id:before {
	content: "\e61d";
}
.pe-7f-mail-open-file:before {
	content: "\e6bb";
}
.pe-7f-film:before {
	content: "\e61e";
}
.pe-7f-magnet:before {
	content: "\e6bc";
}
.pe-7f-disk:before {
	content: "\e61f";
}
.pe-7f-loop:before {
	content: "\e6bd";
}
.pe-7f-delete-user:before {
	content: "\e620";
}
.pe-7f-look:before {
	content: "\e6be";
}
.pe-7f-pin:before {
	content: "\e613";
}
.pe-7f-lock:before {
	content: "\e6bf";
}
.pe-7f-users:before {
	content: "\e60b";
}
.pe-7f-lintern:before {
	content: "\e6c0";
}
.pe-7f-user-female:before {
	content: "\e60c";
}
.pe-7f-link:before {
	content: "\e6c1";
}
.pe-7f-up-arrow:before {
	content: "\e60d";
}
.pe-7f-like:before {
	content: "\e6c2";
}
.pe-7f-switch:before {
	content: "\e60e";
}
.pe-7f-light:before {
	content: "\e6c3";
}
.pe-7f-scissors:before {
	content: "\e60f";
}
.pe-7f-less:before {
	content: "\e6c4";
}
.pe-7f-safe:before {
	content: "\e610";
}
.pe-7f-keypad:before {
	content: "\e6c5";
}
.pe-7f-right-arrow:before {
	content: "\e611";
}
.pe-7f-junk:before {
	content: "\e6c6";
}
.pe-7f-plug:before {
	content: "\e612";
}
.pe-7f-info:before {
	content: "\e6c7";
}
.pe-7f-mute:before {
	content: "\e617";
}
.pe-7f-home:before {
	content: "\e6c8";
}
.pe-7f-help2:before {
	content: "\e6c9";
}
.pe-7f-help1:before {
	content: "\e6ca";
}
.pe-7f-graph3:before {
	content: "\e6cc";
}
.pe-7f-graph2:before {
	content: "\e6cd";
}
.pe-7f-graph1:before {
	content: "\e6ce";
}
.pe-7f-graph:before {
	content: "\e6cf";
}
.pe-7f-global:before {
	content: "\e6d0";
}
.pe-7f-gleam:before {
	content: "\e6d1";
}
.pe-7f-glasses:before {
	content: "\e6d2";
}
.pe-7f-gift:before {
	content: "\e6d3";
}
.pe-7f-folder:before {
	content: "\e6d4";
}
.pe-7f-flag:before {
	content: "\e6d5";
}
.pe-7f-filter:before {
	content: "\e6d6";
}
.pe-7f-file:before {
	content: "\e6d7";
}
.pe-7f-expand:before {
	content: "\e6d8";
}
.pe-7f-expand1:before {
	content: "\e6d9";
}
.pe-7f-edit:before {
	content: "\e6da";
}
.pe-7f-drop:before {
	content: "\e6db";
}
.pe-7f-drawer:before {
	content: "\e6dc";
}
.pe-7f-download:before {
	content: "\e6dd";
}
.pe-7f-display2:before {
	content: "\e6de";
}
.pe-7f-display1:before {
	content: "\e6df";
}
.pe-7f-diskette:before {
	content: "\e6e0";
}
.pe-7f-date:before {
	content: "\e6e1";
}
.pe-7f-cup:before {
	content: "\e6e2";
}
.pe-7f-culture:before {
	content: "\e6e3";
}
.pe-7f-crop:before {
	content: "\e6e4";
}
.pe-7f-credit:before {
	content: "\e6e5";
}
.pe-7f-copy-file:before {
	content: "\e6e6";
}
.pe-7f-config:before {
	content: "\e6e7";
}
.pe-7f-compass:before {
	content: "\e6e8";
}
.pe-7f-comment:before {
	content: "\e6e9";
}
.pe-7f-coffee:before {
	content: "\e6ea";
}
.pe-7f-cloud:before {
	content: "\e6eb";
}
.pe-7f-clock:before {
	content: "\e6ec";
}
.pe-7f-check:before {
	content: "\e6ed";
}
.pe-7f-chat:before {
	content: "\e6ee";
}
.pe-7f-cart:before {
	content: "\e6ef";
}
.pe-7f-camera:before {
	content: "\e6f0";
}
.pe-7f-call:before {
	content: "\e6f1";
}
.pe-7f-calculator:before {
	content: "\e6f2";
}
.pe-7f-browser:before {
	content: "\e6f3";
}
.pe-7f-box1:before {
	content: "\e6f5";
}
.pe-7f-box:before {
	content: "\e6f6";
}
.pe-7f-bookmarks:before {
	content: "\e6f7";
}
.pe-7f-bicycle:before {
	content: "\e6f8";
}
.pe-7f-bell:before {
	content: "\e6f9";
}
.pe-7f-battery:before {
	content: "\e6fa";
}
.pe-7f-ball:before {
	content: "\e6fb";
}
.pe-7f-back:before {
	content: "\e6fc";
}
.pe-7f-attention:before {
	content: "\e6fd";
}
.pe-7f-anchor:before {
	content: "\e6fe";
}
.pe-7f-albums:before {
	content: "\e6ff";
}
.pe-7f-alarm:before {
	content: "\e700";
}
.pe-7f-airplay:before {
	content: "\e701";
}
.pe-7f-close:before {
	content: "\e600";
}
.pe-7f-next:before {
	content: "\e6ad";
}
