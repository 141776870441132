.homeContainerRow{
  display: flex;
  justify-content: center;
  align-items:center;
  padding:60px 20px 20px 20px;
  flex-direction: column;


  .maxWidth{
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;
  }

  .title {
    text-align: center;
    color: #575757;
    font-size: 24px;
    font-weight: 500;
  }

  .divider{
    width: 10%;
    height: 2px;
    background-color: #000000;
    margin:20px 0 20px 0;
  }

  .textContent{
    color: #575757;
    font-size: 18px;
    text-align: center;
    max-width: 1200px;
    width: 100%;
  }

  .actionInnerRow{
    display: flex;
    justify-content: center;
    align-items:center;
    padding:20px 0px;
    flex-direction: column;


    .buttonRow{
      display: flex;
      justify-content: center;
      align-items:center;

      @media(max-width:850px){
        flex-direction: column;
      }
    }

  .actionButton{
    margin:40px 30px 0 30px;
    display: flex;
    flex-direction: column;
    padding: 0;
    border: 0;
    background-color: #fff;
    width:33.3%;

    @media(max-width:850px){
      width:100%;
    }

    &:hover img {
      transform: scale(1.2);
      transition: all .3s ease 0s;
    }

    // &:hover span{
    //   color:$theme-color;
    // }

    .textBox {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border:1px solid #707070;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      width: 100%;
      padding-bottom: 30px;
    }

    img {
      height: 200px;
      width: 100%;
      -webkit-box-shadow: -1px 0px 14px -3px rgba(112,112,112,0.87);
      -moz-box-shadow: -1px 0px 14px -3px rgba(112,112,112,0.87);
      box-shadow: -1px 0px 14px -3px rgba(112,112,112,0.87);
      // border-top-left-radius: 20px;
      // border-top-right-radius: 20px;
    }

    .buttonTitle{
      color:#575757 ;
      font-size: 20px;
      font-weight: 500;
      margin-left: 15px;
      margin-top: 10px;

      &:hover {
        color:$theme-color;
      }
    }

    .buttonText{
      color:#3B3B3B ;
      font-size: 14px;
      margin-left: 15px;
      margin-top: 10px;
      font-weight: 300;

      &:hover {
        color:$theme-color;
      }
    }

  }
  }


}

.container {
  .products {
    .carousel{
      @media(min-width:850px){
        display: none;
      }

      .mobileProductImg{
        height: 20vh;
        object-fit: contain;
      }
    }
  }

  .productRow{
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;

    @media(max-width:850px){
      display: none;
    }

    //.homeProductList{
    //  flex:1 0 0;
    //  max-width: 100%;
    //}
  }
}

.partnerContainer{
    display: flex;
    justify-content: center;
    padding:60px 20px 20px 20px;
    position: relative;

    .maxWidth {
      max-width: 1200px;
      width: 100%;
      display: flex;
    }

  .title {
    text-align: center;
    color: #575757;
    font-size: 24px;
    font-weight: 500;
  }

  .productInner{
    display: flex;
    padding-top:20px;
    cursor: pointer;
    justify-content: center;
    max-width:25%;
    min-width: 25%;
    position: relative;
  }
}

.carousel {
  display: flex;
  justify-content: center;
  overflow: hidden;

  .swiper-button-prev, .swiper-button-next{
    color:  black;
    &:hover{
      color: $theme-color;
    }
    top: 10vh;
  }

  .swiper-button-prev:after, .swiper-button-next:after{
    font-size: 25px;
  }

  .partnerImgView{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 300px;
    width: 100%;

    .partnerImg {
      width: 100%;
      height:200px;
    }
  }

  .productDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .textBox {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border:1px solid #707070;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    min-width: 100%;
    background-position: center;
    padding: 40px;
    background-repeat: no-repeat;
    background-size: contain;

    img{
      height: 50px;
    }

  }

  .swiper-pagination-bullet-active {
    background: $theme-color;
  }

}

.home-blog-more {

  display: flex;
  justify-content: center;

  a {
    color: #fff !important;
    font-weight: bold;
    text-transform: uppercase;
    border: none;
    background-color: #343538;
    display: inline-block;
    line-height: 1;
    padding: 23px 38px 23px;
    z-index: 99;
  }
}
