/*------ 25. My account Page  ------*/
.single-my-account {
  margin-bottom: 20px;
  border: 1px solid #ebebeb;
  .panel-heading {
    padding: 0;
    & > button {
      background: none;
      border: none;
      width: 100%;
      text-align: left;
      padding: 0;
    }
  }

  .nav-link {
    @media(max-width:980px){
      padding: 0.5rem 0px;
    }
  }

  .card-body {
    border-bottom: 1px solid #ebebeb;
  }
  h3.panel-title {
    background-color: #f9f9f9;
    border-bottom: 1px solid #ebebeb;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
    color: #242424;
    display: block;
    padding: 16px 55px;
    position: relative;
    @media #{$xs-layout} {
      line-height: 22px;
      font-size: 14px;
      padding: 16px 30px 16px 40px;
    }

    &:hover {
      color: $theme-color;
    }
    &::before {
      color: #000;
      content: "\f107";
      display: inline-block;
      font-family: "FontAwesome";
      font-size: 16px;
      position: absolute;
      right: 10px;
      top: 19px;
    }
    &:hover::before {
      color: $theme-color;
    }

    span {
      color: #242424;
      font-size: 15px;
      left: 20px;
      position: absolute;
      top: 16px;
    }
  }
  .myaccount-info-wrapper {
    padding: 30px 20px;
    background-color: #fff;

    .table th {
      border-top:none;
    }

    form {
      width:100%;
      align-items: flex-start;
    }
    .account-info-wrapper {
      border-bottom: 1px solid #eaeaea;
      margin-bottom: 28px;
      padding-bottom: 30px;

      &:not(:first-child){
        margin-top: 40px;
      }
      h4 {
        font-size: 15px;
        margin: 0;
        text-transform: uppercase;
        color: #575757;
      }
      h5 {
        font-size: 16px;
        letter-spacing: 0.2px;
        margin-top: 7px;
        color: #575757;
      }
    }
    .billing-info {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      label {
        color: #000;
        font-size: 14px;
        text-transform: capitalize;
      }
      input {
        background: transparent none repeat scroll 0 0;
        border: 1px solid #ebebeb;
        color: #000;
        height: 40px;
        padding: 0 15px;
        width: 100%;
      }


    }
    .billing-back-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 26px;
      align-items: center;
      .billing-back {
        a {
          color: #000;
          display: inline-block;
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;
          &:hover {
            color: $theme-color;
          }
          i {
            font-size: 16px;
            color: $theme-color;
          }
        }
      }
      .billing-btn {
        .save-btn,
        button {
          background-color: $theme-color;
          border: medium none;
          color: #fff;
          display: inline-block;
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
          padding: 16px 35px 17px;
          width: 100%;
          text-transform: uppercase;
          cursor: pointer;
          transition: all 0.3s ease 0s;
          &:hover {
            background: #f2f2f2;
            color: #000;
          }
        }
        .round-btn,
        button {
          background-color: $theme-color;
          border: medium none;
          color: #fff;
          display: inline-block;
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
          padding: 8px 30px 8px;
          border-radius: 5px;
          width: 100%;
          text-transform: uppercase;
          cursor: pointer;
          transition: all 0.3s ease 0s;
          &:hover {
            background: #f2f2f2;
            color: #000;
          }
        }
        .round-btn-disable,
        button {
          background-color: #f2f2f2;
          border: medium none;
          color: #fff;
          display: inline-block;
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
          padding: 8px 30px 8px;
          border-radius: 5px;
          width: 100%;
          text-transform: uppercase;
          cursor: pointer;
          transition: all 0.3s ease 0s;
          &:hover {
            background: #f2f2f2;
            color: #fff;
          }
        }
      }
    }
    .entries-wrapper {
      border: 1px solid #eaeaea;
      position: relative;
      @media #{$xs-layout} {
        padding: 30px 10px;
        &::before {
          display: none;
        }
      }
      //&::before {
      //  position: absolute;
      //  content: "";
      //  height: 100%;
      //  width: 1px;
      //  left: 50%;
      //  top: 0;
      //  background-color: #eaeaea;
      //}
      .entries-info {
        padding: 30px 20px;
        @media #{$xs-layout} {
          padding: 0 10px 30px;
        }
        p {
          color: #000;
          font-size: 15px;
          margin: 0;
          text-transform: capitalize;
        }
      }
      .entries-edit-delete {
        a,
        button {
          background: none;
          border: none;
          background-color: #000;
          color: #fff;
          display: inline-block;
          line-height: 1;
          margin: 0 2px;
          padding: 12px 15px;
          text-transform: uppercase;
          font-weight: 500;
          &:hover {
            background-color: $theme-color;
          }
          &.edit {
            background-color: #df5c39;
            &:hover {
              background-color: $theme-color;
            }
          }
        }
      }
    }


    .shipmentAddress{
      border-bottom: 1px solid #E3E3E3;
      padding-bottom: 20px;
      display: flex;
      flex-direction: row;

      &:not(:first-child){
        margin-top: 20px;
      }

    .addressDetailRow{
      padding-right:10px;
      width: 80%;

      @media (max-width:850px){
        width: 100%;
      }

      h4,h5 {
        font-size: 14px;
        color:#666666;
      }

      p {
        color: #575757;
        font-size: 14px;
        font-weight:100;
        font-family: 'Roboto';
        padding:0;
        margin:0;
      }
    }

    .addressFunctionRow{
      display: flex;

      @media (max-width:850px){
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
      }

      .functionDiv {
        @media (max-width:850px){
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      p{
        color: #575757;
        font-size: 14px;
        font-weight:lighter;
        padding:0;
        margin:0;
      }

      div {
        margin:0px 10px;

        @media (max-width:850px){
          margin-left:0
        }
      }

      .delectDiv{
        justify-content: center;
        align-items: center;
        display: flex;

        button{
          margin-top:40px;
          border:0;
          background-color: transparent;

          @media (max-width:850px){
            margin-top:0px;
          }
        }

      }

      .checkboxRow {
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;

        .custom-checkbox-input-container {
          display: block;
          position: relative;
          padding-left: 0px;
          margin-bottom: 0px;
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        .custom-checkbox-input-checkmark {
          left: -15px;

          @media (max-width:850px){
            left: 10px;
            top:-10px;
          }
        }
      }
    }

    @media (max-width: 850px) {
      flex-direction: column;
    }




    }
  }
  .bold-text {
    font-weight: 600;
  }

  .lightgrey {
    color: #575757;
    font-size: 14px;
  }
  .subTitle {
    font-weight: 600;
    font-size: 20px;
    color: #575757;
  }
  .mainTitle {
    font-size: 30px;
  }
  .membership-status-bar {
    border-width: 2px;
    border-color: $theme-color;
    border-style: solid;
    width: 93%;
    padding: 5px;
    height: 25px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    div {
      background-color: $theme-color;
      height: 100%;
    }
  }
  .membership-expenditure {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .amount {
      width: 85%;

    }
    .next-tier {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 15%;
    }
  }
  .credit-table {
    text-align: center;
  }

  .nav-link.active {
    color: $theme-color !important;
  }

  .coupons-container{
    padding: 30px 20px;
    background-color: #fff;

    .account-info-wrapper {
      border-bottom: 1px solid #eaeaea;
      margin-bottom: 28px;
      padding-bottom: 30px;

      .couponsTitle{
        color: #000000;
        font-family: "Poppins", sans-serif;
        font-size: 30px;
        line-height: 24px;
        font-weight: 600;
        margin-top: 0px;
      }

      &:not(:first-child){
        margin-top: 40px;
      }
      h4 {
        font-size: 15px;
        margin: 0;
        text-transform: uppercase;
        color: #575757;
      }
      h5 {
        font-size: 24px;
        letter-spacing: 0.2px;
        margin-top: 7px;
        color: #575757;
      }
    }


    .coupons-row {
      margin-top: 10px;

      &:not(:first-child){
        margin-top: 40px;
      }

      h4 {
        font-size: 18px;
        margin: 0;
        color: #666666;
        font-weight: 400;
        text-align: center;
      }

      .couponsDiv{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        position: relative;



        @media(max-width:1000px){
          flex-direction: column;
          border-top: 1px solid #E3E3E3;

        }

        .detailContainer{
          flex: 25%;

          h4 {
            color: #575757;
            font-size: 14px;
          }

          h5 {
            color: #575757;
            font-size: 18px;
          }

          @media(max-width:1000px){
            margin-top: 10px;
            justify-content: space-between;
            display: flex;
          }

          @media(max-width:760px){
            flex-direction: column;
            align-items: flex-start;
          }

        }

        .expiredDetailContainer{
          flex:25%;

          @media(max-width:1000px){
            margin-top: 10px;
            justify-content: space-between;
            display: flex;
          }

          @media(max-width:760px){
            flex-direction: column;
            align-items: flex-start;
          }


          h4 {
            font-size: 14px;
            color: #E3E3E3;
            text-align: center;
          }

          h5 {
            font-size: 18px;
            color:#E3E3E3;
            font-weight: 100;
            font-family: 'Roboto';
            text-align: center;
          }
        }

        .detailDiv {
          width: 100%;
          height: 100%;
          justify-content:flex-end;
          padding-top: 20px;
          border-top: 1px solid #E3E3E3;

          @media(max-width:1000px){
            padding-top: 0px;
            width: auto;
            border-top: 0;
          }

          @media(max-width:760px){
           margin-top: 5px;
          }
        }

        h4 {
          font-size: 15px;
          color:#888;
          text-align: center;
        }

        h5 {
          font-size: 18px;
          color:#575757;
          font-weight: 100;
          font-family: 'Roboto';
          text-align: center;
        }

        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          @media(max-width:1000px){
            flex-direction: row;
            margin-top: 0px;
          }
        }
      }
    }

  }
}
